<template>
  <div class="work_main">
    
      <div class="tabs">
        <div class="ali_box">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            ref="ali"
            class="ali"
            :class="item.value == medalActive ? 'tabActive' : ''"
            @click="handleTabClick($event, item.value, index)"
          >
            {{ item.lable }}
          </div>
        </div>

        <div class="active_bar" :style="{ left: bar_let}"></div>
      </div>
      <a-spin :spinning="spinning" :delay="delayTime">
      <div class="work_list">
        
          <div class="search_box">
              <div class="search_inp" v-show="classify==1?true:false">
                <a-input-search placeholder="搜索作品编号、作品名、作者" v-model="searchVal" style="width: 349px;" @search="onSearch"/>
              </div>
              <!--3,实操报名,4 上传作品,5申请答辩 6获得证书  -->
              <div class="btn_box" v-if="btnStatus" @click="createMyWork">
                <img src="@/assets/imgs/cer/up_icon.png" width="20" height="20" alt="">
                上传作品
              </div>
          </div>
          <div v-if="list&&list.length>0">
              <div class="list_cont">
                <a-row :gutter="30">
                    <a-col class="gutter-row" :span="6" v-for="(item,index) in list" :key="index">
                      <div class="list_item_box" @click="()=>$router.push({path:'/VCworkDetails',query:{wId:item.id,mId:$route.query.id}})">
                          <div class="worl_num">
                            <p>NO.<span>{{item.id}}</span></p>
                          </div>
                          <img :src="item.courseLogo!=null&&item.courseLogo!=''?QiNiuPath+item.courseLogo:''" alt="">
                          <div class="text_box">
                            <h5>{{item.name}}</h5>
                            <p>
                              <span class="time">{{item.createdTime}}</span>
                              <span class="author" v-if="medalActive=='all'">{{item.userName}}</span>
                            </p>
                          </div>
                      </div>
                    </a-col>
                  </a-row>
              </div>
              <div class="pag_box">
                  <a-pagination show-quick-jumper :total="total" :pageSize="pageSize" @change="chengePage"/>
              </div>
          </div>
          <div style="position:absolute;top:calc(50% - 65px);left:0;width:100%;" v-else-if="spinning==false&&list.length<=0">
              <a-empty/>
          </div>
      </div>
      </a-spin>
      <a-modal v-model="visible" title="上传作品" :footer="null" width="700px" centered>
          <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          @submit="handleSubmit"
          class="my_form"
        >
          <a-form-item label="作品标题">
            <a-input
              v-decorator="[
                'username',
                {
                  rules: [{ required: true, message: '请输入作品标题' }],
                },
              ]"
              placeholder="请输入作品标题"
              maxLength="30"
            />
          </a-form-item>
          <a-form-item label="视频">
            <a-upload
              v-decorator="[
                'video',
                {
                  rules: [{ required: true,validator:videoCB, message: '请上传作品视频' }]
                },
              ]"
              name="file"
              :action="QNUpload"
              :data="fileData"
              :file-list="fileList"
              :before-upload="handleBefore"
              :remove="handleRemove"
              @change="handleChange"
            >
              <a-button> <a-icon type="upload" />点击上传</a-button>
              <span style="font-size: 13px;
                  color: #9f9f9f;
                  margin-left: 24px;">注：支持.MP4、.mov视频格式，最大不超过30M<span>
            </a-upload>
          </a-form-item>
          <a-form-item label="作品简介（选填）" class="info">
            <a-textarea v-decorator="[
                'info'
              ]"
              placeholder="请输入描述" :rows="4" 
              maxLength="200"
            />
          </a-form-item>
          <a-form-item class="btn_box">
            <a-button
              class="btn"
              type="primary"
              shape="round"
              html-type="submit"
              :disabled="btnLoading"
              >保存</a-button
            >
            <a-button
              class="btn"
              shape="round"
              @click="closeModal"
              >取消</a-button
            >
          </a-form-item>
        </a-form>
      </a-modal>
  </div>
</template>
<script>
import { getToken, giveLessonsWorks, uploadWork } from "@/apis/certificate.js";
import { mapState } from "vuex";
export default {
  name: "work",
  data() {
    return {
      tabs: [
        { lable: "全部作品", value: "all" },
        { lable: "我的作品", value: "my" },
      ],
      medalActive: "all",
      bar_let: 0,
      searchVal: "",
      classify: 1,
      //Spin参数
      spinning: true,
      delayTime: 10,
      //分页参数
      current: 1,
      pageSize: 12,
      total: 0,
      //列表
      list: [],
      //创建作品
      visible: false,
      form: this.$form.createForm(this),
      fileList: [],
      //上传到七牛的参数
      QNDomain: "",
      QNUpload: "",
      fileData: {
        //附件
        key: "", //文件名
        token: "",
      },
      //上传完成的视频路径
      upAfterPath: "",
      upAfterSize: 0,
      fileName:'',
      //上传作品按钮
      btnLoading:false,
    };
  },
  props: ["details"],
  created() {
    this.getList();
    this.getQNToken();
  },
  watch: {
    medalActive(newVal) {
      //tab切换-调用列表
      this.classify = newVal == "all" ? 1 : 2;
      this.spinning = true;
      this.current = 1;
      this.getList();
      console.log("1111");
    },
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
    btnStatus() {
      let type = false;
      let param = this.details;
      if (
        param.certificationStatus != null &&
        param.certificationStatus != undefined
      ) {
        let status = param.certificationStatus;
        if (status == 3 || status == 4 || status == 5) {
          type = true;
        }
      }
      return type;
    },
  },
  methods: {
    //一级tab切换
    handleTabClick(ev, val, index) {
      let ali = this.$refs.ali;
      let one_ali_x = ali[0].getBoundingClientRect().left; //第一个li距离视窗左边距离
      let active_ali_x = ali[index].getBoundingClientRect().left; //当前选中li距离视窗左边距离
      this.bar_let = `${active_ali_x - one_ali_x}px`;
      this.medalActive = val;
    },
    getList() {
      giveLessonsWorks({
        currentPage: this.current,
        pageSize: this.pageSize,
        name: this.searchVal,
        classify: this.classify,
        giveLessonsId: this.$route.query.id,
      }).then((res) => {
        let msg = res.data;
        if (msg.status == 0) {
          this.total = msg.result.total;
          this.list = msg.result.medalGiveLessonsWorksList.map((item)=>{
            console.log(item)
            return {...item,createdTime:item.createdTime.substring(0,10)};
          });
          this.spinning = false;
        }
      });
    },
    onSearch() {
      this.spinning = true;
      this.current = 1;
      this.getList();
    },
    chengePage(page, pageSize) {
      let p = page;
      this.current = p;
      this.getList();
    },
    //创建作品
    createMyWork() {
      this.visible = true;
      this.$nextTick(() => {
        let dom = document.getElementsByClassName("ant-modal-close")[0];
        dom.style.top = 0;
        dom.style.right = 0;
      });
    },
    //保存-作品
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let that = this;
          // that.btnLoading = true;
          let params = {
            giveLessonsId: that.$route.query.id,
            name: values.username,
            description: values.info!=undefined&&values.info!=''&&values.info!=null?values.info:'',
            fileAddress: values.video.file.response.key,
            fileSize: Math.round(that.upAfterSize),
            fileName:that.fileName,
          };
          uploadWork(params).then((res) => {
            let msg = res.data;
            if (msg.status == 0) {
              that.$message.success("成功");
               setTimeout(() => {
                 that.getList();
                 that.visible = false;
               }, 1500); 
            } else {
              that.btnStatus = false;
              that.$message.error("提交失败");
            }
          });
        }
      });
    },
    //取消-作品
    closeModal() {
      this.form.resetFields();
      this.visible = false;
    },
    handleBefore(file) {
      this.upAfterSize = file.size / 1024;
      this.fileName=file.name;
      this.getQNToken();
      return new Promise((resolve, reject) => {
        const lowerType = file.type.toLowerCase();
        const isMp4OrMov =
          lowerType === "video/mp4" || lowerType === "video/quicktime";
        if (!isMp4OrMov) {
          this.$message.error("请上传 MP4、mov格式视频!");
          return reject(false);
        }
        const isLi30M = file.size / 1024 / 1024 < 30;
        if (!isLi30M) {
          this.$message.error("请上传小于 30MB 的视频");
          return reject(false);
        }
        return resolve(true);
      });
    },
    handleChange(info) {
      console.log(info);
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
    },
    handleRemove() {
      this.fileList = [];
    },
    //获取七牛token
    getQNToken() {
      getToken({}).then((res) => {
        console.log(res);
        let msg = res.data;
        if (msg.status == 0) {
          this.QNDomain = msg.result.domain;
          this.QNUpload = msg.result.upload;
          this.fileData.token = msg.result.token;
          this.fileData.key = msg.result.key;
        }
      });
    },
    //自定义校验
    videoCB(rule,value,callback){
      return this.fileList.length>0?true:false;
      callback();
    }
  },
};
</script>
<style lang="scss" scoped>
.work_main {
  width: 1200px;
  margin: 39px auto 0 auto;

  .tabs {
    width: 249px;
    height: 46px;
    position: relative;
    margin: 0 auto 34px auto;
    .ali_box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .ali {
        float: left;
        font-size: 20px;
        font-weight: 400;
        color: #606978;
        line-height: 28px;
        cursor: pointer;
        &:first-child {
          margin-left: 0 !important;
        }
        &:last-child {
          float: right;
          margin-right: 0 !important;
        }
      }
    }
    .tabActive {
      color: #5d5aed !important;
      font-weight: 600;
    }
    .active_bar {
      background-color: rgba(0, 0, 0, 0);
      background: url(../../../../assets/imgs/cer/activeIcon.png) top center
        no-repeat;
      background-size: auto 100%;
      height: 6px;
      width: 80px;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.5s;
    }
  }
  .work_list {
    background-color: #fff;
    padding-top: 18px;
    padding-left: 31px;
    padding-right: 31px;
    padding-bottom: 29px;
    min-height: 400px;
    max-height: auto;
    position: relative;
    .search_box {
      overflow: hidden;
      width: 100%;
      position: relative;
      padding-bottom: 14px;
      padding-left: 6px;
      padding-right: 6px;
      &::after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: -4px;
        left: 0;
        border: 1px solid #F6F6F6;
      }
      .search_inp {
        margin-top: 5px;
        display: inline-block;
        ::v-deep .ant-input {
          border-radius: 999px;
          height: 42px;
          padding: 4px 16px 4px 15px;
        }
        ::v-deep .ant-input-suffix {
          font-size: 22px;
        }
      }
      .btn_box {
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        color: #5d5aed;
        line-height: 25px;
        padding: 12px 28px;
        border-radius: 999px;
        border: 1px solid #5d5aed;
        float: right;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
    .list_cont {
      padding-top: 15px;
      .list_item_box {
        width: 260px;
        height: 222px;
        background: #ffffff;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-bottom: 26px;
        cursor: pointer;
        position: relative;
        .worl_num{
          position: absolute;
          top: 0;
          left: 0;
          width:83px;
          background: url('../../../../assets/imgs/cer/num_bg.png')top center no-repeat;
          background-size: 100%;
          border-top-left-radius: 4px;
          p{
            font-size: 13px;
            font-weight: normal;
            color: #FFFFFF;
            line-height: 14px;
            padding: 6px 17px 5px 9px;
          }
        }
        img {
          width: 260px;
          height: 148px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        .text_box {
          width: 100%;
          padding: 13px 19px;
          h5 {
            font-size: 18px;
            font-weight: 500;
            color: #606978;
            line-height: 25px;
            min-width: auto;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          p {
            font-size: 12px;
            font-weight: 500;
            color: #b9b9b9;
            line-height: 17px;
            margin-top: 4px;
            overflow: hidden;
            .time{
              float: left;
            }
            .author{
              float: right;
            }
          }
        }
      }
    }
    .pag_box {
      text-align: center;
    }
  }
}

.my_form {
  .info {
    ::v-deep .ant-input {
      height: auto !important;
    }
  }
  ::v-deep .ant-input {
    height: 40px;
  }
  .btn_box {
    text-align: center;
    ::v-deep .ant-col {
      width: 100%;
    }
    ::v-deep .ant-btn-round {
      padding: 0 24px;
      border-radius: 999px;
      height: 40px;
      font-size: 20px;
      margin: 0 12px;
    }
  }
}
</style>